// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import '../stylesheets/core.scss'

const feather = require('feather-icons');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../module/core/bootstrap.bundle.min'
import '../module/core/tiny-slider'
// import '../module/core/feather.min'
import '../module/core/plugins.init'
import '../module/core/app'

function toggleMenu() {
  document.getElementById('isToggle').classList.toggle('open');
  var isOpen = document.getElementById('navigation')
  if (isOpen.style.display === "block") {
      isOpen.style.display = "none";
  } else {
      isOpen.style.display = "block";
  }
};

const main = () => {
  feather.replace();

  const toggle = document.querySelector('#isToggle');
  if (toggle) {
    toggle.addEventListener('click', () => {
      toggleMenu();
    });
  }
};

document.addEventListener("DOMContentLoaded", () => {
  main();
});
